<template>
  <div>

    <b-row class="match-height">
        <b-card-group class="col-md-6">
          <b-card title="Welcome to Testudy! 🚀">
            <b-card-text>
              <ol>
                <li class="" style="margin-bottom: 0.5em;">Press 
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    @click="routeToCreateText"
                    class="ml-1"
                  >
                    Create your test
                  </b-button>                  
                  </li>
                <li class="mb-1">Choose test type:
                  <ul>
                    <li><b>based on text</b>, you need to paste your own text and to choose an algorithm: <i>Socrates</i> - basic, <i>Plato</i> - advanced</li>
                    <li><b>based on subject</b> to create a test without the source text</li>
                  </ul>  
                </li>
                <li class="mb-1">Press <b>Refresh<b/></b> to see if your test is ready</li>
                <li class="mb-1">Click on the test to open it</li>
              </ol>
            </b-card-text>
          </b-card>
      </b-card-group>  
      <b-card-group class="col-md-6">
          <b-card title="There are two question generation algorithms on Testudy">
            <b-card-text>
              <p><b>Socrates</b> is a free but less accurate question generation algorithm. It's available both in Free and Premium plans.</p>
              <p><b>Plato</b> is an AI-based algorithm available only to Premium users. According to our testers, there's no difference between Plato's performance and human-asked questions.</p>
            </b-card-text>
            <b-card-text>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                @click="routeToCreateText"
              >
                Create your test
              </b-button>
            </b-card-text>

          </b-card>
      </b-card-group>  
    </b-row>


    <b-row class="match-height mt-1">
        <b-card-group class="col-md-8">
          <b-card no-body class="card-statistics" >
          <b-card-header>
            <b-card-title>Account</b-card-title>
            <b-card-text class="mr-25 mb-0">
              {{ userData.name }}
            </b-card-text>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row class="match-height">

              <b-col md="4" sm="6" class="mb-2 mb-md-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar v-if="userData.is_premium" size="48" variant="success"><feather-icon size="24" icon="UserIcon" /></b-avatar>
                    <b-avatar v-else size="48" variant="danger"><feather-icon size="24" icon="UserIcon" /></b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">Account Status</h4>
                    <b-card-text class="font-small-3 mb-0">{{ getStatus() }}</b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col md="4" sm="6" class="mb-2 mb-md-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar v-if="userData.is_premium" size="48" variant="info"><feather-icon size="24" icon="CalendarIcon" /></b-avatar>
                    <b-avatar v-else size="48" variant="success"><feather-icon size="24" icon="AwardIcon" /></b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">Subscription</h4>
                    <b-card-text class="font-small-3 mb-0">
                          <span v-if="!userData.is_premium"><b><a href="/profile">Get premium</a></b></span>
                          <span v-else>Due {{ userData.subscription_period_end | formatDate }}</span> 
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>


              <b-col md="4" sm="6" class="mb-2 mb-md-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="primary"><feather-icon size="24" icon="ListIcon" /></b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">Quizzes</h4>
                    <b-card-text class="font-small-3 mb-0"> 
                          <span v-if="userData.is_premium">{{ userData.questions_created }}</span><span v-else class="text-danger">{{ userData.questions_created }}</span>
                          <span v-if="!userData.is_premium" class="text-danger"> / {{ freeAccountLimit }}</span><span v-else> / ∞</span> 
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>        

              
            </b-row>
            </b-card-body>
          </b-card>
        </b-card-group>  
        
        <b-card-group class="col-md-4">
          <b-card no-body class="card-statistics" >
          <b-card-header>
            <b-card-title>Spent characters</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row class="match-height">
              <b-col md="6" sm="6" class="mb-2 mb-md-0">
                <b-media no-body>
                  <b-media-body class="my-auto">
                    <div class="progress-wrapper">
                      <b-card-text class="mb-0">
                        <h4 class="font-weight-bolder mb-0">Socrates</h4>
                        {{ userData.symbols_free_alg }} / {{ limit_to_str(userData.char_limit_alg_free) }} 
                      </b-card-text>
                      <b-progress
                        v-model="userData.symbols_free_alg"
                        :max="limit_to_int(userData.char_limit_alg_free)"
                        :variant="getVariant(userData.symbols_free_alg, userData.char_limit_alg_free)"
                      />
                    </div>
                  </b-media-body>
                </b-media>
              </b-col>        
              <b-col md="6" sm="6" class="mb-2 mb-md-0">
                <b-media no-body>
                  <b-media-body class="my-auto">
                    <div class="progress-wrapper">
                      <b-card-text class="mb-0">
                        <h4 class="font-weight-bolder mb-0">Plato</h4>
                        {{ userData.symbols_paid_alg }} / {{ limit_to_str(userData.char_limit_alg_paid) }}
                      </b-card-text>
                      <b-progress
                        v-model="userData.symbols_paid_alg"
                        :max="limit_to_int(userData.char_limit_alg_paid)"
                        :variant="getVariant(userData.symbols_paid_alg, userData.char_limit_alg_paid)"
                      />
                    </div>
                  </b-media-body>
                </b-media>
              </b-col>  
              
            </b-row>
            </b-card-body>
          </b-card>
        </b-card-group>  

    </b-row>


    <b-row class="match-height mt-1">
        <b-card-group class="col-md-12">
          <b-card title="Sample tests from our users">
            <b-card-text>
              <b-row class="match-height">
                <b-col md="3" sm="12" class="mb-1">
                  <h4>Medicine</h4>
                  <a href="/quiz/b0acfa6f-5c13-475e-a040-e1a2c2358851">Test about immunology</a>
                </b-col>
                <b-col md="3" sm="12" class="mb-1">
                  <h4>Sociology</h4>
                  <a href="/quiz/edf976f6-c0ea-45ed-82ca-1d998454bc9c">Test about culture</a>
                </b-col>
                <b-col md="3" sm="12" class="mb-1">
                  <h4>Geography</h4>
                  <a href="/quiz/e4756983-92f6-4007-8fb9-9febfb945870">Test about the history of geography</a>
                </b-col>
                <b-col md="3" sm="12" class="mb-1">
                  <h4>Chemistry</h4>
                  <a href="/quiz/0e19f139-d3d1-4ea0-bea9-1c1a41a80fcc">Test about chemical solutions</a>
                </b-col>                                                
              </b-row>
            </b-card-text>
          </b-card>
      </b-card-group>  
    </b-row>


  </div>
</template>

<script>
import { BCard, BCardText, BLink, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BButton, BProgress, BCardGroup  } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'  
import Ripple from 'vue-ripple-directive'



export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BCardHeader, BCardTitle, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BButton, BProgress, BCardGroup    
  },
  directives: {
    Ripple,
  },   
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      freeAccountLimit: 200,

    }
  },
  methods: {
    getStatus() {
        if (this.userData.is_premium) {
          return 'Premium'
        } else {
          return 'Limited'
        }
      },
      getUserProfile() {
        useJwt.getUserProfile({
          // src_text: this.textQuiz,
        })
        .then(response => {
          const resData = response.data
          // console.log("response.data:")
          // console.log(response.data)
          // this.items = resData
          // Set the initial number of items
          

          this.userData.id = resData[0].id;
          this.userData.name = resData[0].name;
          this.userData.credits = resData[0].credits;
          this.userData.email = resData[0].email;
          this.userData.is_premium = resData[0].is_premium;
          this.userData.subscription_period_end = resData[0].subscription_period_end;
          this.userData.stripe_customer_id = resData[0].stripe_customer_id; 
          this.userData.questions_created = resData[0].questions_created; 

          this.userData.symbols_free_alg = resData[0].symbols_free_alg; 
          this.userData.symbols_paid_alg = resData[0].symbols_paid_alg; 
          this.userData.char_limit_alg_free = resData[0].char_limit_alg_free; 
          this.userData.char_limit_alg_paid = resData[0].char_limit_alg_paid;           
          this.userData.subscription_name = resData[0].subscription_name; 

          localStorage.setItem('userData', JSON.stringify(this.userData))
          // document.getElementById('nb-username').innerHTML =this.userData.name;
          this.updateNavbarProfile();
          // NavBar.userData = this.userData;
          // window.location.reload(true);
          // NavBar.forceReload += 1;
          // store.state.appConfig.globalUserData = userData;
          // console.log(this.$store.appConfig.globalUserData);
          // console.log(store.state.appConfig.isRTL)
        })
        .catch(error => {
          //this.$refs.loginForm.setErrors(error.data);
          // console.log(error)
          if (error.status == 401) {
            this.logout();
          } else {            
          this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Error getting User Profile",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.data.email,
                },
              }) 
          }     
        }) 
        .finally(()=>{ 
          // this.seenTable = true        
        });
      },        
      updateNavbarProfile() {
        document.getElementById('nb-username').innerHTML = this.userData.name;
        var ps =  document.getElementById('profile-state').getElementsByClassName('b-avatar-badge')[0];
        if (this.userData.is_premium) {
          ps.classList.remove('badge-secondary');
          ps.classList.add('badge-success');
        } else {
          ps.classList.remove('badge-success');          
          ps.classList.add('badge-secondary');
        }
      },
      routeToCreateText() {
        this.$router.push({ name: 'addtext' })        
      },
      routeToQuizzes() {
        this.$router.push({ name: 'quizzes-page' })        
      },    

      limit_to_str(lim) {
        return lim > 0 ? lim : '∞'
      },

      limit_to_int(lim) {
        return lim > 0 ? lim : 2147483647
      },      

      getVariant(symbols, limit) {
        if (limit == 0) {
          return 'success'
        } else {
          if ((symbols/limit) > 0.7 ) {
            return 'danger'
          } else
          if ((symbols/limit) > 0.4 ) {
            return 'warning'
          } else return 'success'

        }

      }
  },
  mounted() {
    this.getUserProfile();
    
    },    

}
</script>

<style>

</style>
